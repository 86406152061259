function animateIn(entries) {
  var i, n, entry;

  for (i = 0, n = entries.length; i < n; i++) {
    entry = entries[i];
    if (entry.intersectionRatio < 0.4) continue;
    entry.target.setAttribute("data-animation-state", "entered");
  }
}

function watchScroll() {
  if (!(window.IntersectionObserver && document.querySelectorAll)) return;

  var animator = new IntersectionObserver(animateIn, { threshold: 0.4 }),
    containers = document.querySelectorAll("[data-scroll-trigger]"),
    i,
    n,
    el;

  for (i = 0, n = containers.length; i < n; i++) {
    el = containers[i];
    el.setAttribute("data-animation-state", "staged");
    animator.observe(el);
  }
}

window.addEventListener("DOMContentLoaded", watchScroll);
